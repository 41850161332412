<template>
  <div class="main_container">
    <el-row>
      <el-col :span="4" v-for="(o, index) in project" :key="o.myurl" :offset="index > 0 ? 2 : 0">
        <a :href="o.myurl">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img :src="o.imgurl" class="image" />
            <div class="title" style="padding: 14px;">
              <span>{{o.title}}</span>
            </div>
          </el-card>
        </a>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      project: [
        {
          myurl: 'http://106.14.139.10/#/login',
          imgurl: 'http://106.14.139.10/img/breaddog.cf40164b.jpg',
          title: 'vue_shop'
        },
        {
          myurl: 'http://106.14.139.10:8002',
          imgurl: 'https://gitee.com/xiaoerbing/img/raw/master/img/20210917115721.png',
          title: '购物动画'
        },
        {
          myurl: 'https://xiaoerbing.gitee.io',
          imgurl: 'https://gitee.com/xiaoerbing/img/raw/master/img/20210917115846.jpg',
          title: '个人博客'
        },
        {
          myurl: 'http://xiaoerbing.top:8003',
          imgurl: 'http://106.14.139.10:8003/img/g2.jpg',
          title: '瀑布流'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  margin: 0 auto;
  width: 280px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}
.el-card {
  height: 300px;
  position: relative;
}
.el-card:hover {
  background-color: #999;
  transition-duration: 500ms;
}
.title {
  left: 50%;
  position: absolute;
  bottom: 5px;
  transform: translate(-50%, 0);
}
span {
  font-size: 1rem;
  font-weight: 1000;
}
</style>